html, body {
    position: relative;
    height: 100%
}

.wippy-container {
    width: 100%;
    height: 100%
}

@media screen and (max-device-width: 320px) {
    .wippy-container .store-btn-area .btn img {
        cursor: pointer;
    }

    .wippy-container .title-text-area img {
        width: 198px
    }

    .wippy-container .store-btn-area .btn {
        margin-top: 49px
    }

    .wippy-container .store-btn-area .btn img {
        width: 167px
    }

    .wippy-container .store-btn-area .text {
        margin-top: 4px
    }

    .wippy-container .store-btn-area .text img {
        display: none
    }
}

@media screen and (min-device-width: 321px) and (max-device-width: 1023px) {
    .wippy-container .title-text-area img {
        width: 258px
    }

    .wippy-container .store-btn-area .btn {
        margin-top: 43px
    }

    .wippy-container .store-btn-area .btn img {
        width: 194px
    }

    .wippy-container .store-btn-area .text {
        margin-top: 7px
    }

    .wippy-container .store-btn-area .text img {
        display: none
    }

    .wippy-container .store-btn-area .text img.google, .wippy-container .store-btn-area .text img.app-store {
        display: flex;
        width: 188px
    }
}

@media screen and (min-device-width: 1024px) {
    .wippy-container .title-text-area img {
        width: 382px
    }

    .wippy-container .store-btn-area .btn {
        margin-top: 26px
    }

    .wippy-container .store-btn-area .btn img {
        width: 194px
    }

    .wippy-container .store-btn-area .text {
        margin-top: 7px
    }

    .wippy-container .store-btn-area .text img {
        display: none
    }

    .wippy-container .store-btn-area .text img.google-app-store {
        display: flex;
        width: 368px
    }
}

.wippy-container .store-btn-area .text img.google, .wippy-container .store-btn-area .text img.app-store {
    display: flex;
    width: 188px
}

.flex-box {
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center
}

.flex-box-row {
    flex-direction: row
}

.flex-content-left {
    -webkit-align-items: flex-start;
    align-items: flex-start
}

.flex-content-right {
    -webkit-align-items: flex-end;
    align-items: flex-end
}

.flex-1 {
    -webkit-flex: 1;
    flex: 1
}

.flex-2 {
    -webkit-flex: 2;
    flex: 2
}

.flex-3 {
    -webkit-flex: 3;
    flex: 3
}
